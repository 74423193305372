'use strict'
const _ = require('lodash')
const santaPlatformUtils = require('santa-platform-utils')

function generateGhostCompId(compId, controllerId, itemId) {
    const ghostId = compId + controllerId
    return itemId ? santaPlatformUtils.repeaterUtils.structure.getUniqueDisplayedId(ghostId, itemId) : ghostId
}

function getChildrenForGhost(ghosts, childRoles, controllerId) {
    return _.map(childRoles, role => generateGhostCompId(ghosts[role].id, controllerId))
}

function buildDisplayedOnlyComp({fallbackStructure, comp, compRole, repeaterId, controllerId, parentId, item}) {
    return _.assign({}, comp, {
        id: compRole,
        structureId: generateGhostCompId(comp.id, controllerId, item),
        data: comp.data || {},
        design: comp.design || {},
        type: comp.componentType,
        displayedOnlyComponents: [],
        isDisplayed: true,
        displayedRoot: generateGhostCompId(parentId, controllerId),
        children: _.map(comp.children, child => generateGhostCompId(fallbackStructure[child].id, controllerId, item)),
        parent: generateGhostCompId(parentId, controllerId, parentId === repeaterId ? null : item)
    })
}

function getChildren(parent) {
    return _.map(_.get(parent, 'children', []), childRole => ({childRole, parentId: parent.id}))
}

function createGhostDisplayedOnlyComps({fallbackStructure, repeater, displayedOnlyComps, controllerId}) {
    const repeaterItems = _.get(repeater, 'data.items')

    let repeaterChildren = getChildren(repeater)
    while (repeaterChildren.length > 0) {
        const {childRole, parentId} = repeaterChildren.shift()
        const childComp = _.get(fallbackStructure, childRole)

        const displayedCompsForRole = _.map(repeaterItems, item => buildDisplayedOnlyComp({
            fallbackStructure,
            comp: childComp,
            compRole: childRole,
            repeaterId: repeater.id,
            controllerId,
            parentId,
            item
        }))

        displayedOnlyComps.set(childRole, displayedCompsForRole)

        const children = getChildren(childComp)
        repeaterChildren = repeaterChildren.concat(children)
    }

    return displayedOnlyComps
}

module.exports = {
    createGhostDisplayedOnlyComps,
    getChildrenForGhost,
    generateGhostCompId
}