'use strict'

const wixCodeRemoteModelService = require('./utils/wixCodeRemoteModelService')
const WidgetAspect = require('./core/WidgetAspect')
const modelBuilder = require('./core/modelBuilder')
const globalsBuilder = require('./core/globalsBuilder')
const modelBuilderDataHelper = require('./core/modelBuilderDataHelper')
const widgetBehaviorHandler = require('./behaviors/widgetBehaviorHandler')
const widgetBehaviorPreprocessor = require('./behaviors/widgetBehaviorPreprocessor')
const messageBuilder = require('./messages/messageBuilder')
const widgetModel = require('./utils/widgetModel')
const widgetService = require('./core/widgetService')

module.exports = {
    wixCodeRemoteModelService,
    messageBuilder,
    modelBuilder,
    globalsBuilder,
    widgetModel,
    widgetBehaviorHandler,
    widgetBehaviorPreprocessor,
    WidgetAspect,
    modelBuilderDataHelper,
    widgetService
}
