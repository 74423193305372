'use strict'

const _ = require('lodash')
const wixCodeRemoteModelService = require('../utils/wixCodeRemoteModelService')

function build(dataAPI, isMockSiteData, contextId, options, livePreviewMode) {
    const navigationData = dataAPI.RGI.fetchNavigationData(isMockSiteData, _.get(options, 'pageInfo'))
    const routerData = dataAPI.RGI.fetchRouterData(contextId)
    const sessionInfo = dataAPI.RGI.fetchSessionInfo()
    const siteRevision = dataAPI.RGI.fetchSiteRevision()
    const siteMemberData = dataAPI.RGI.fetchSiteMemberData()
    const multilingualInfo = dataAPI.RGI.fetchMultilingualInfo()
    const appsData = dataAPI.RGI.fetchAppsData()
    const deviceType = dataAPI.RGI.fetchDeviceType()
    const isMobileFriendly = dataAPI.RGI.fetchIsMobileFriendly()
    const regionalSettings = _.invoke(dataAPI, 'RGI.fetchRegionalSettings')
    const isApplicationStudio = dataAPI.RGI.fetchIsApplicationStudio()
    return wixCodeRemoteModelService.generateRemoteGlobalsInterface({
        navigationData,
        sessionInfo,
        siteRevision,
        siteMemberData,
        multilingualInfo,
        appsData,
        isApplicationStudio,
        deviceType,
        lightboxContext: _.get(options, 'lightboxContext'),
        contextId,
        livePreviewMode,
        isMobileFriendly,
        regionalSettings,
        routerData
    })
}

module.exports = {
    build
}
