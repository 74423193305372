'use strict'

const _ = require('lodash')

const behaviorIdentiferFields = ['type', 'name', 'targetId']

function handle(behaviors, siteAPI, event) {
    const widgetAspect = siteAPI.getSiteAspect('WidgetAspect')
    const handler = widgetAspect.getWidgetHandler()
    _.forEach(behaviors, behavior => {
        handler.handleEvent(behavior.targetId, behavior.name, behavior.params, event)
    })
}

function getUniqueIdentifier(behavior) {
    const identifiers = _.at(behavior, behaviorIdentiferFields)
    identifiers.push(behavior.params.callbackId)
    identifiers.push(behavior.params.compId)
    return identifiers.join(',')
}

module.exports = {
    handle,
    getUniqueIdentifier
}
