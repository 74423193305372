'use strict'

const _ = require('lodash')
const constants = require('../plarformInit/utils/constants')

function isWixCodeMessage(msgIntent) {
    return msgIntent === constants.WIX_CODE.MESSAGE_INTENT.WIX_CODE_MESSAGE
}

function isWixCodeSiteAPIMessage(msgIntent) {
    return msgIntent === constants.WIX_CODE.MESSAGE_INTENT.WIX_CODE_SITE_API
}

function isWixCodeAppAPI(msgIntent) {
    return msgIntent === constants.WIX_CODE.MESSAGE_INTENT.WIX_CODE_APP_API
}

function handleWixCodeMessage(remoteMessagesHandler, hostProps, msg, callback) {
    const widgetHandler = remoteMessagesHandler
    switch (msg.type) {
        case constants.WIX_CODE.MESSAGE_TYPE.IFRAME_COMMAND:
            widgetHandler.onCommand(msg, callback) // TODO: use handleRemoteMessage instead
            break
        case constants.WIX_CODE.MESSAGE_TYPE.CONSOLE:
            _.invoke(hostProps, 'logsHandler', msg)
            break
        case constants.WIX_CODE.MESSAGE_TYPE.REQUEST_API:
            _.invoke(hostProps, 'handleRequestAPIMessage', widgetHandler, msg)
            break
        case constants.WIX_CODE.MESSAGE_TYPE.PERFORMANCE_METRICS_READY:
            if (typeof window !== 'undefined') {
                window.postMessage(msg, '*')
            }
            break
        default:
            widgetHandler.handleRemoteMessage(msg)
            break
    }
}
// TODO onMessage is only exposed for the queue and for the wixcode-integration, it can be removed if wixCodePostMessageQueue is moved inside the postMessagesService and integration is switched to EliRunner
function onMessage({remoteMessagesHandler, hostProps = {}}, message, handler) { // eslint-disable-line complexity
    handler = handler || _.partial(handleWixCodeMessage, remoteMessagesHandler, hostProps)
    try {
        if (_.isString(message)) {
            message = JSON.parse(message)
        }
    } catch (e) {
        return
    }
    const updatedMessage = hostProps.preMessageHandlingHook ?
        hostProps.preMessageHandlingHook(message, handler) : message

    if (updatedMessage) {
        let responseFn
        if (isWixCodeMessage(message.intent)) {
            handler(message, generateResponseFunction(message, remoteMessagesHandler))
        } else if (isWixCodeSiteAPIMessage(message.intent)) {
            const handlers = hostProps.hostAPIHandlers || {}
            responseFn = generateResponseFunction(message, remoteMessagesHandler)
            try {
                if (hostProps.genericHandler) {
                    hostProps.genericHandler(message, responseFn)
                } else if (handlers[message.type]) {
                    handlers[message.type](message, responseFn)
                }
            } catch (error) {
                responseFn(null, error.message)
            }
        } else if (isWixCodeAppAPI(message.intent)) {
            responseFn = generateResponseFunction(message, remoteMessagesHandler)
            try {
                _.invoke(hostProps, 'handleWixCodeAppApiMessage', message, responseFn)
            } catch (error) {
                responseFn(null, error.message)
            }
        }
    }
}

function generateResponseFunction(msg, widgetHandler) {
    return function (data, error, ports) {
        let message = {
            intent: 'WIX_CODE_RESPONSE',
            callbackId: msg.callbackId,
            type: msg.type,
            contextId: msg.contextId
        }

        if (isWixCodeSiteAPIMessage(msg.intent) || isWixCodeAppAPI(msg.intent)) {
            message = _.merge(message, {
                result: data,
                error
            })
        } else {
            const result = {
                compId: msg.compId,
                command: msg.command
            }

            if (data) {
                _.merge(result, data)
            }

            message = _.merge(message, {result})
        }
        try {
            widgetHandler.updateComponent(message, ports)
        } catch (e) {
            //TODO - log the error
        }
    }
}

function handleMessage({remoteMessagesHandler, hostProps = {}}, message, handler) {
    onMessage({remoteMessagesHandler, hostProps}, message, handler)
}

function registerMessageModifier(wixCodeAppApi, modifier) {
    wixCodeAppApi.registerMessageModifier(modifier)
}

function registerWixCodeMessageHandler({wixCodeAppApi, remoteMessagesHandler, hostProps}) {
    wixCodeAppApi.registerMessageHandler(_.partial(handleMessage, {remoteMessagesHandler, hostProps}))
}
module.exports = {
    registerWixCodeMessageHandler,
    registerMessageModifier,
    onMessage
}