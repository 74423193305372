'use strict'

const wixDomSanitizer = require('wix-dom-sanitizer')
const {svgUtils, guidUtils, requestsUtil} = require('santa-core-utils')
const {mediaItemUtils} = require('santa-platform-utils')
const {SOURCE_TYPES, EMPTY_SVG_ID, EMPTY_SHAPE} = svgUtils
const {nameUUIDFromString: toGUID} = guidUtils
const fetchOtions = {
    method: 'GET',
    headers: {'content-type': 'text/plain'},
    mode: 'cors'
}
const urlMatcher = /^https?:\/\//
const inlineMatcher = /^\s*<(\?xml|svg)/i

/**
 * Return the type of src
 * @param {MediaSrc} mediaSrc
 * @returns {string}
 */
function getSourceType(mediaSrc) {
    const {value, isValid} = mediaSrc

    if (!isValid) {
        return SOURCE_TYPES.EMPTY
    }

    if (value) {
        if (urlMatcher.test(value)) {
            return SOURCE_TYPES.URL
        }
        if (inlineMatcher.test(value)) {
            return SOURCE_TYPES.INLINE
        }
        if (mediaItemUtils.isValidMediaItemUri(value, mediaItemUtils.types.VECTOR)) {
            return SOURCE_TYPES.WIX_MEDIA_ID
        }
    }
    return SOURCE_TYPES.EMPTY
}


/**
 * Pass svg string through HTML Sanitizer
 * @param svgString
 * @returns {*}
 */
function parseAndCleanSvg(svgString) {
    const {svg, error} = wixDomSanitizer.sanitizeSVG(svgString)

    if (!svg) {
        console.warn(error) //eslint-disable-line no-console
        return EMPTY_SHAPE
    }
    return svg
}

/**
 * Handle external svg url
 * If the source of the exrernal is already saved in cache, return it,
 * else return the src and resolve svgId async via ajax call to the external source
 * @param {SiteAPI} siteAPI
 * @param {MediaSrc} mediaSrc
 * @param {string} compId
 * @returns {{svgId?: string, mediaSrc: MediaSrc}}
 */
function handleExternalSvg(siteAPI, mediaSrc, compId) {
    const svgId = toGUID(mediaSrc.value)
    const pointers = siteAPI.getPointers()
    const displayedDAL = siteAPI.getDisplayedDAL()
    const runtimeDAL = siteAPI.getRuntimeDal()
    const svgStorePointer = pointers.svgShapes.getSvgShapePointer(svgId)
    const svgStoreData = displayedDAL.get(svgStorePointer)

    const error = () => {
        //TODO: log error
        const {mediaSrc: lastSetSource = {}} = runtimeDAL.getCompData(compId)
        if (mediaSrc.value === lastSetSource.value) {
            runtimeDAL.setCompData(compId, {svgId: EMPTY_SVG_ID, mediaSrc})
        }
    }

    //Always save SVGs to store but set to component only the last called svg
    const success = response => response.text().then(svgString => {
        displayedDAL.set(svgStorePointer, siteAPI.getSvgStringToStoreData(parseAndCleanSvg(svgString)))
        const {mediaSrc: lastSetSource = {}} = runtimeDAL.getCompData(compId)
        if (mediaSrc.value === lastSetSource.value) {
            runtimeDAL.setCompData(compId, {svgId, mediaSrc})
        }
    })

    if (!svgStoreData) {
        requestsUtil
            .fetch(mediaSrc.value, fetchOtions)
            .then(success)
            .catch(error)

        return {mediaSrc}
    }

    return {svgId, mediaSrc}
}

/**
 * Handle inline svg source
 * if in cache, return svgId, else, parse content, create a new id and save to store
 * @param {SiteAPI} siteAPI
 * @param {MediaSrc} mediaSrc
 * @returns {{svgId: string, mediaSrc: MediaSrc}}
 */
function handleInlineSvg(siteAPI, mediaSrc) {
    const svgId = toGUID(mediaSrc.value)
    const pointers = siteAPI.getPointers()
    const displayedDAL = siteAPI.getDisplayedDAL()
    const svgStorePointer = pointers.svgShapes.getSvgShapePointer(svgId)
    const svgStoreData = displayedDAL.get(svgStorePointer)

    if (!svgStoreData) {
        displayedDAL.set(svgStorePointer, siteAPI.getSvgStringToStoreData(parseAndCleanSvg(mediaSrc.value)))
    }

    return {svgId, mediaSrc}
}

function handleSvgMediaItemUrl(mediaSrc) {
    const parsed = mediaItemUtils.parseMediaItemUri(mediaSrc.value)
    return {
        svgId: parsed.mediaId,
        title: parsed.title,
        mediaSrc
    }
}

/**
 *
 * @param {{mediaSrc?: MediaSrc}}data
 * @param {SiteAPI} siteAPI
 * @param {object} props
 * @returns {{}|{svgId: string, mediaSrc: MediaSrc}}
 */
function resolve(data, siteAPI, {compId}) {
    if (data.type !== 'VectorImage' || !data.hasOwnProperty('mediaSrc')) {
        return data
    }
    const {mediaSrc} = data
    const sourceType = getSourceType(mediaSrc)

    switch (sourceType) {
        case SOURCE_TYPES.WIX_MEDIA_ID:
            return handleSvgMediaItemUrl(mediaSrc)
        case SOURCE_TYPES.URL:
            return handleExternalSvg(siteAPI, mediaSrc, compId)
        case SOURCE_TYPES.INLINE:
            return handleInlineSvg(siteAPI, mediaSrc)
        case SOURCE_TYPES.EMPTY:
        default:
            return {svgId: EMPTY_SVG_ID, mediaSrc}
    }
}

module.exports = {
    resolve
}

/**
 * @typedef {object} MediaSrc
 * @property {string} value
 * @property {boolean} isValid
 */