'use strict'

const _ = require('lodash')
const mobx = require('mobx')
const coreUtilsLib = require('santa-core-utils')
const modelBuilder = require('./modelBuilder')
const globalsBuilder = require('./globalsBuilder')
const messageBuilder = require('../messages/messageBuilder')

const CommandTypes = {
    State: 'stateChanged',
    Data: 'dataChanged',
    Design: 'designChanged',
    Props: 'propsChanged',
    EventRegister: 'registerEvent',
    Layout: 'layoutChanged',
    Behavior: 'executeBehavior',
    Style: 'styleChanged',
    ExecuteBatch: 'executeBatch'
}

const MessageTypes = {
    WidgetReady: 'widget_ready', //remove when merging sv_handleFailingWixCodeSdk
    WidgetStatusChanged: 'widget_status_changed',
    WarmupData: 'wix_code_warmup_data'
}

const WidgetStatus = {
    pending: 'pending',
    success: 'success',
    failed: 'failed'
}

function onRemoteModelInterfaceUpdate({compId, type, data, callback}) {
    const typeToMethod = {
        data: this._runtimeDal.setCompData.bind(this._runtimeDal),
        style: this._runtimeDal.updateCompStyle.bind(this._runtimeDal),
        design: this._runtimeDal.setCompDesign.bind(this._runtimeDal),
        props: this._runtimeDal.setCompProps.bind(this._runtimeDal),
        layout: this._runtimeDal.updateCompLayout.bind(this._runtimeDal),
        registerEvent: this._runtimeDal.registerComponentEvent.bind(this._runtimeDal)
    }

    if (compId) {
        typeToMethod[type](compId, data)
    } else {
        typeToMethod[type](data)
    }


    if (_.isFunction(this._onUpdateCallback)) {
        this._onUpdateCallback(callback)
    }
}

function buildContextModels(contextIds) {
    if (this._aspectProps.shouldUpdateRuntimeModels()) {
        return modelBuilder.attach(this._aspectProps.getRuntimeModels(), contextIds, onRemoteModelInterfaceUpdate.bind(this))
    }
    const dataAPI = this._aspectProps.RMIDataAPI

    return modelBuilder.build({
        runtimeDal: this._runtimeDal,
        dataAPI,
        contextIds,
        onModelUpdateCallback: onRemoteModelInterfaceUpdate.bind(this),
        componentsFetcher: this._aspectProps.componentsFetcher,
        getCompReactClass: this._aspectProps.getCompReactClass,
        performanceLogger: this._aspectProps.performanceLogger,
        getGhostStructure: this._aspectProps.getGhostStructure,
        isTypeController: this._aspectProps.isTypeController,
        canUseOnlyConnectedComponents: !this._aspectProps.hasWixCode() && this._aspectProps.isExperimentOpen('sv_reducedRMI')
    })
}

function buildRootsGlobals(rootIds, pagesInfo, popupContexts, livePreviewMode) {
    const keyedPagesInfo = _.keyBy(pagesInfo, 'pageId')
    const dataAPI = this._aspectProps.RMIDataAPI

    return _.transform(rootIds, (result, rootId) => {
        result[rootId] = globalsBuilder.build(dataAPI, false, rootId, {pageInfo: keyedPagesInfo[rootId], lightboxContext: popupContexts[rootId]}, livePreviewMode)
    }, {})
}

function pushCommandToQueue(message, callback) {
    function platformCommandAction() {
        if (this.isWidgetReady(message.contextId)) {
            this.handleCommand(message, callback)
            return
        }
        pushCommandToQueue.call(this, message, callback)
    }
    this._actionQueue.addItem(platformCommandAction.bind(this))
}

function flushPendingCommands(flushNow) {
    if (this._isFlushingPendingCommands) {
        return
    }

    const self = this

    this._isFlushingPendingCommands = true

    function runCommands() {
        self._isFlushingPendingCommands = false
        self._actionQueue.flush()
        notifyCommandsFlushedListeners(self._commandsFlushListeners)
        self._aspectProps.refreshRenderedContextsData()
    }

    if (flushNow) {
        runCommands()
        return
    }
    coreUtilsLib.animationFrame.request(runCommands)
}

function notifyCommandsFlushedListeners(listeners) {
    _.forEach(listeners, callback => callback())
}

function RemoteWidgetHandlerProxy(rendererServices, onUpdateCallback, flushWidgetReady) {
    this._runtimeDal = rendererServices.runtimeDal
    this._wixCodeAppApi = rendererServices.wixCodeAppApi
    this._remoteModelInterfaces = {}
    this._remoteGlobalsInterfaces = {}
    this._onUpdateCallback = onUpdateCallback
    this._receivedChanges = undefined
    this._isFlushingPendingCommands = false
    this._commandsFlushListeners = []
    this._actionQueue = rendererServices.actionQueue
    this._storageAPI = rendererServices.storageAPI
    this._flushWidgetReady = flushWidgetReady
    this._aspectProps = rendererServices.aspectProps
    this._widgetsLoaded = false
}

function getActiveWidget(widgetId) {
    return this._remoteModelInterfaces[widgetId]
}

function getEvent(event) {
    const result = _.merge(_.pick(event, 'item'), _.pickBy(event, _.negate(_.isObject)))
    result.nativeEvent = _.pickBy(event.nativeEvent, _.negate(_.isObject))
    if (event.data) {
        result.data = event.data
    }
    return result
}

function setWidgetState(widgetId, value) {
    this._storageAPI.set(`${widgetId}_widgetState`, value)
}

function getWidgetState(widgetId) {
    return this._storageAPI.get(`${widgetId}_widgetState`)
}

RemoteWidgetHandlerProxy.prototype.initWidgets = function (contexts, options) {
    const message = messageBuilder.initWidgetsMessage(contexts, options)
    this._sendMessage(message)
}

RemoteWidgetHandlerProxy.prototype.hasRmiChangedSinceLastStart = function (contextIds) {
    if (!this.lastLivePreviewRmiUsed) {
        return true
    }
    const newRMI = buildContextModels.call(this, contextIds)
    return _.some(contextIds, contextId => !_.isEqual(
        this.lastLivePreviewRmiUsed[contextId], newRMI[contextId].jsonWithoutWixCodeComps(contextId)
    ))
}

RemoteWidgetHandlerProxy.prototype.livePreviewWorker = function () {
    return this.lastLivePreviewRmiUsed
}

RemoteWidgetHandlerProxy.prototype.cleanLivePreviewWorker = function () {
    this.lastLivePreviewRmiUsed = null
}

RemoteWidgetHandlerProxy.prototype.startWidgets = function (contextIds, options) {
    if (_.isEmpty(contextIds)) {
        return
    }

    if (_.get(options, 'resetRuntimeOverrides')) {
        _.forEach(contextIds, contextId => {
            this._runtimeDal._siteDataApi.resetRuntimeOverrides(contextId, true)
            this._runtimeDal._siteDataApi.createDisplayedPage(contextId)
        })
    }

    _.assign(this._remoteModelInterfaces, buildContextModels.call(this, contextIds))
    const contextIdToModelMap = _.mapValues(this._remoteModelInterfaces, contextRMI => {
        if (_.get(options, 'livePreviewMode') && contextRMI.cleanModelForLivePreview) {
            contextRMI.cleanModelForLivePreview()
        }
        return contextRMI.toJson()
    })

    const message = messageBuilder.startWidgetsMessage(_.pick(contextIdToModelMap, contextIds), options)
    this.lastLivePreviewRmiUsed = _.get(options, 'livePreviewMode') ?
        _.mapValues(this._remoteModelInterfaces, (contextRMI, contextId) =>
            contextRMI.jsonWithoutWixCodeComps(contextId)) : null
    this._sendMessage(message)
}

RemoteWidgetHandlerProxy.prototype.loadUserCode = function (widgetsIdAndType, rootIds, doNotLoadUserCode) {
    const baseMessage = messageBuilder.loadUserCodesMessage(widgetsIdAndType, rootIds)
    const widgets = doNotLoadUserCode ? _.pickBy(baseMessage.widgets, x => _.isMatch(x, {type: 'Application'})) : baseMessage.widgets
    const message = _.assign(baseMessage, {widgets}, doNotLoadUserCode ? {doNotLoadUserCode} : {})
    this._sendMessage(message)
}

RemoteWidgetHandlerProxy.prototype.loadUserGeneratedApps = function (widgetsIdAndType, rootIds) {
    const message = messageBuilder.loadUserGeneratedAppsMessage(widgetsIdAndType, rootIds)
    this._sendMessage(message)
}

RemoteWidgetHandlerProxy.prototype.updateWixCodeModelDataAfterLogin = function (widgetsIdAndType, rootIds) {
    const message = messageBuilder.updateWixCodeModelDataAfterLoginMessage(widgetsIdAndType, rootIds)
    this._sendMessage(message)
}

RemoteWidgetHandlerProxy.prototype.setEditorAppPublicApiToViewerWorker = function (apiName, rootIds) {
    const message = messageBuilder.setEditorAppPublicApiToViewerWorker(apiName, rootIds)
    this._sendMessage(message)
}

/**
 * @param widgetsIdAndType {{id: string, type: string}[]} an array of widgets' id & type
 * @param rootIds
 */
RemoteWidgetHandlerProxy.prototype.loadWidgets = function (widgetsIdAndType, rootIds, pagesInfo, livePreviewMode, doNotLoadUserCode) {
    const userGeneratedApps = _.filter(widgetsIdAndType, {isWixTPA: false})

    this.loadUserGeneratedApps(userGeneratedApps, rootIds)

    const popupContexts = _(rootIds)
        .transform((obj, rootId) => {
            obj[rootId] = this._runtimeDal.getPopupContext(rootId)
        }, {})
        .omitBy(_.isUndefined)
        .value()

    this._widgetsLoaded = true

    _.assign(this._remoteGlobalsInterfaces, buildRootsGlobals.call(this, rootIds, pagesInfo, popupContexts, livePreviewMode))
    const rootIdToGlobalsMap = _.mapValues(this._remoteGlobalsInterfaces, rootRGI => rootRGI.toJson())
    const baseMessage = messageBuilder.loadWidgetsMessage(widgetsIdAndType, this._aspectProps.routers, rootIds, rootIdToGlobalsMap)
    const message = _.assign(baseMessage, {livePreviewMode, doNotLoadUserCode: livePreviewMode && doNotLoadUserCode})
    this._sendMessage(message)
}

RemoteWidgetHandlerProxy.prototype.getActiveWidgetIds = function () {
    return _.keys(this._remoteModelInterfaces)
}

RemoteWidgetHandlerProxy.prototype.stopWidgets = function (contextIds) {
    if (_.isEmpty(contextIds)) {
        return
    }
    _.forEach(contextIds, contextId => {
        delete this._remoteModelInterfaces[contextId]
        delete this._remoteGlobalsInterfaces[contextId]
        setWidgetState.call(this, contextId, this._aspectProps.isExperimentOpen('sv_handleFailingWixCodeSdk') ? WidgetStatus.pending : false)
    })
    const message = messageBuilder.stopWidgetsMessage(contextIds)
    this._sendMessage(message)
}

RemoteWidgetHandlerProxy.prototype.stopAllWidgets = function () {
    this.stopWidgets(_.keys(this._remoteModelInterfaces))
}

RemoteWidgetHandlerProxy.prototype.updateComponent = function (message, ports) {
    this._sendMessage(message, ports)
}

RemoteWidgetHandlerProxy.prototype.invokeWorkerSubscribers = function (workerId, appDefId, data) {
    this._sendMessage(messageBuilder.invokeWorkerSubscribersMessage(workerId, appDefId, data))
}

RemoteWidgetHandlerProxy.prototype.handleWidgetUpdate = function (compUpdates) {
    const compId = _(compUpdates).keys().head()//is it possible to update more than one component at a time?
    const contextToRmi = _.pickBy(this._remoteModelInterfaces, rmi => _.has(rmi.toJson(), ['components', compId]))
    const currentChanges = _.find(_.find(compUpdates))
    if (!_.isEmpty(contextToRmi) && !_.isEqual(this._receivedChanges, currentChanges)) {
        const contextId = _(contextToRmi).keys().head()
        contextToRmi[contextId].updateModel(compUpdates)
        const message = messageBuilder.updateWidgetMessage(contextId, compUpdates)
        this._sendMessage(message)
    }
}

RemoteWidgetHandlerProxy.prototype.handleSiteMemberUpdate = function (siteMemberData) {
    if (!this._widgetsLoaded) {
        const message = messageBuilder.updateSiteMemberData(this._aspectProps.currentUrlPageId, siteMemberData)
        this._sendMessage(message)
    }

    _.forEach(this._remoteGlobalsInterfaces, (rgi, contextId) => {
        rgi.addSiteMemberData(siteMemberData)
        const message = messageBuilder.updateSiteMemberData(contextId, siteMemberData)
        this._sendMessage(message)
    })
}

RemoteWidgetHandlerProxy.prototype.handleBiDataUpdate = function (contextId, updates) {
    const message = messageBuilder.updateBiData(contextId, updates)
    this._sendMessage(message)
}

RemoteWidgetHandlerProxy.prototype.handleMultilingualInfoUpdate = function (multilingualInfo) {
    _.forEach(this._remoteGlobalsInterfaces, (rgi, contextId) => {
        rgi.addMultilingualInfo(multilingualInfo)
        const message = messageBuilder.updateMultilingualInfo(contextId, multilingualInfo)
        this._sendMessage(message)
    })
}

RemoteWidgetHandlerProxy.prototype.triggerAppStudioWidgetOnPropsChanged = function (contextId, oldProps, newProps) {
    const rmi = this._remoteModelInterfaces[contextId]
    if (rmi) { rmi.updateWidgetProperties(newProps) }

    const message = messageBuilder.triggerAppStudioWidgetOnPropsChanged(contextId, oldProps, newProps)
    this._sendMessage(message)
}

RemoteWidgetHandlerProxy.prototype.handleAppDataUpdate = function (appsData) {
    _.forEach(this._remoteGlobalsInterfaces, (rgi, contextId) => {
        rgi.addAppsData(appsData)
        const message = messageBuilder.updateAppsData(contextId, appsData)
        this._sendMessage(message)
    })
}

RemoteWidgetHandlerProxy.prototype.handleSvSessionUpdate = function (svSession) {
    if (!this._widgetsLoaded) {
        const message = messageBuilder.updateSessionInfo(this._aspectProps.currentUrlPageId, {svSession})
        this._sendMessage(message)
    }

    _.forEach(this._remoteGlobalsInterfaces, (rgi, contextId) => {
        rgi.addSessionInfoProp(svSession)
        const message = messageBuilder.updateSessionInfo(contextId, {svSession})
        this._sendMessage(message)
    })
}

RemoteWidgetHandlerProxy.prototype.handleLocationUpdate = function (navigationData) {
    _.forEach(this._remoteGlobalsInterfaces, (rgi, contextId) => {
        rgi.addNavigation(navigationData)
        const message = messageBuilder.updateaNavigation(contextId, navigationData)
        this._sendMessage(message)
    })
}

function resolveData(Dal, compId, data) {
    const dataToResolve = _.assign({}, Dal.getCompData(compId), data)
    const compProps = Dal.getCompProps(compId)
    return this._aspectProps.resolveWidgetData(dataToResolve, {compProps, compId})
}

function resolveDataForBatch(Dal, changes) {
    return _.assign({}, _.mapValues(changes, (change, compId) => {
        if (change.data) {
            return _.assign(change, {data: resolveData.call(this, Dal, compId, change.data)})
        }
        return change
    }))
}

RemoteWidgetHandlerProxy.prototype.handleRemoteMessage = function (message) {
    coreUtilsLib.wSpy.log('worker', [`remote w->s ${message.type}`, message])
    switch (message.type) {
        case MessageTypes.WidgetStatusChanged:
            if (this._aspectProps.isExperimentOpen('sv_handleFailingWixCodeSdk')) {
                if (getActiveWidget.call(this, message.widgetId)) {
                    mobx.runInAction(() => {
                        setWidgetState.call(this, message.widgetId, message.status)
                        if (message.status === WidgetStatus.success) {
                            flushPendingCommands.call(this, true)
                            this._flushWidgetReady(message.widgetId)
                        }
                    })
                }
            }
            break
        case MessageTypes.WidgetReady:
            if (!this._aspectProps.isExperimentOpen('sv_handleFailingWixCodeSdk')) {
                if (getActiveWidget.call(this, message.widgetId)) {
                    mobx.runInAction(() => {
                        setWidgetState.call(this, message.widgetId, true)
                        flushPendingCommands.call(this, true)
                        this._flushWidgetReady(message.widgetId)
                    })
                }
            }
            break
        case MessageTypes.WarmupData:
            const controllerId = _.get(message.data, 'controllerId')
            const data = _.get(message.data, 'data')
            this._aspectProps.setWarmupDataForController(controllerId, data)
            break
    }
}

RemoteWidgetHandlerProxy.prototype.onCommand = function (message, callback) {
    pushCommandToQueue.call(this, message, callback)

    if (this.isWidgetReady(message.contextId)) {
        flushPendingCommands.call(this, message.command === CommandTypes.EventRegister)
    }
}

// TODO: move onCommand to be a private function that is called from inside handleRemoteMessage
RemoteWidgetHandlerProxy.prototype.handleCommand = function (message, callback) { // eslint-disable-line complexity
    coreUtilsLib.wSpy.log('worker', [`command w->s ${message.command}`, ...arguments])
    this._receivedChanges = message.data
    const RMI = this._remoteModelInterfaces[message.contextId]
    if (!RMI) {
        return
    }
    switch (message.command) {
        case CommandTypes.ExecuteBatch:
            message.data = resolveDataForBatch.call(this, this._runtimeDal, message.data)
            RMI.setBatchData(message.data)
            break
        case CommandTypes.State:
            RMI.setState(message.compId, message.data)
            break
        case CommandTypes.Data:
            message.data = resolveData.call(this, this._runtimeDal, message.compId, message.data)
            RMI.setData(message.compId, message.data)
            break
        case CommandTypes.Design:
            RMI.setDesign(message.compId, message.data)
            break
        case CommandTypes.Layout:
            RMI.setLayout(message.compId, message.data)
            break
        case CommandTypes.Props:
            RMI.setProps(message.compId, message.data, callback)
            break
        case CommandTypes.EventRegister:
            RMI.registerEvent(message.contextId, message.compId, message.data.eventType, message.data.controllerId, message.data.callbackId)
            break
        case CommandTypes.Style:
            RMI.setStyle(message.compId, message.data)
            break
        case CommandTypes.Behavior:
            const behavior = message.data
            const event = {group: 'command', callback, clear: _.get(behavior.params, 'clear', true)}
            this._aspectProps.handleProcessedBehavior(behavior, event)
            break
    }
    this._receivedChanges = undefined
}

RemoteWidgetHandlerProxy.prototype.handleEvent = function (contextId, name, params, event) {
    let message
    switch (name) {
        case 'runCode':
            message = messageBuilder.triggerUserFunctionMessage(contextId, params, getEvent(event))
            break
        case 'onRendered':
            message = messageBuilder.triggerOnRenderMessage(contextId, params)
            break
        default:
            break
    }
    this._sendMessage(message)
}

RemoteWidgetHandlerProxy.prototype.handleNativeTpaEvent = function (contextId, {callbackId, eventArgs, compId}) {
    const message = messageBuilder.handleNativeTpaEventMessage(contextId, {callbackId, eventArgs, compId})
    this._sendMessage(message)
}

RemoteWidgetHandlerProxy.prototype.isWidgetReady = function (widgetId) {
    if (this._aspectProps.isExperimentOpen('sv_handleFailingWixCodeSdk')) {
        return getWidgetState.call(this, widgetId) === WidgetStatus.success
    }
    return !!getWidgetState.call(this, widgetId)
}

RemoteWidgetHandlerProxy.prototype.isWidgetFailed = function (widgetId) {
    return getWidgetState.call(this, widgetId) === WidgetStatus.failed
}

RemoteWidgetHandlerProxy.prototype._sendMessage = function (message, ports) {
    coreUtilsLib.wSpy.log('worker', [`send s->w ${message.type}`, ...arguments])
    const wixCodeAppApi = this._wixCodeAppApi

    wixCodeAppApi.sendMessage(message, ports)
}

RemoteWidgetHandlerProxy.prototype.getPostMessageTarget = function (workerId) {
    const wixCodeAppApi = this._wixCodeAppApi
    const useWorkersDirectly = this._aspectProps.isExperimentOpen('wixCodeNoIframe') && this._aspectProps.isViewerMode
    return useWorkersDirectly ? wixCodeAppApi.getWorkerById(workerId) : wixCodeAppApi.getAppsIframe()
}

RemoteWidgetHandlerProxy.prototype.registerCommandsFlushedListener = function (callback) {
    if (!_.isFunction(callback)) {
        throw new TypeError('The callback provided is not a function.')
    }
    this._commandsFlushListeners.push(callback)
}

module.exports = RemoteWidgetHandlerProxy
