'use strict'

const _ = require('lodash')

function calculateTargetId(siteAPI, behaviorSourceId) {
    const sourceRootId = siteAPI.getRuntimeDal().getPageId(behaviorSourceId)
    if (!sourceRootId) {
        return null
    }
    if (sourceRootId === 'masterPage') {
        return siteAPI.getSiteData().getFocusedRootId()
    }
    return sourceRootId
}

function fixWidgetBehaviorTarget(behavior, action, siteAPI) {
    return _.assign({}, behavior, {targetId: calculateTargetId(siteAPI, action.sourceId)})
}

module.exports = fixWidgetBehaviorTarget
