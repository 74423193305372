'use strict'

const _ = require('lodash')
const pageLinkDataResolver = require('./dataResolvers/pageLinkDataResolver')
const emptyImageDataResolver = require('./dataResolvers/emptyImageDataResolver')
const vectorImageDataResolver = require('./dataResolvers/vectorImageDataResolver')

const dataResolvers = [
    pageLinkDataResolver,
    emptyImageDataResolver,
    vectorImageDataResolver
]

module.exports = {
    resolve(data, siteAPI, compInfo) {
        _.forEach(dataResolvers, resolver => {
            data = resolver.resolve(data, siteAPI, compInfo)
        })
        return data
    }
}
