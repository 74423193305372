'use strict'

const _ = require('lodash')
const coreUtilsLib = require('santa-core-utils')
const ghostUtils = require('./ghostUtils')

const REPEATER_TYPE = 'wysiwyg.viewer.components.Repeater'

function getInitialState(propsInfo, compType, getCompReactClass) {
    const compClass = getCompReactClass(compType)
    if (compClass && compClass.publicState) {
        return compClass.publicState(null, propsInfo)
    }

    return {}
}

function shouldResolveCompDataFromFull(compId, isDescendantOfRepeater) {
    return !coreUtilsLib.displayedOnlyStructureUtil.isDisplayedComponent(compId) && isDescendantOfRepeater
}

function getAncestorOfRepeaterType(runtimeDal, compId) {
    return runtimeDal.getAncestorOfType(compId, REPEATER_TYPE)
}

function getCompModel({runtimeDal, compId, contextId, getCompReactClass}) {
    const compType = runtimeDal.getCompType(compId)
    const repeaterComp = getAncestorOfRepeaterType(runtimeDal, compId)
    const isDescendantOfRepeater = !!repeaterComp
    const resolveCompDataFromFull = shouldResolveCompDataFromFull(compId, isDescendantOfRepeater)
    const compState = getCompState(runtimeDal, compId, compType, getCompReactClass, resolveCompDataFromFull)
    const compActionsAndBehaviors = runtimeDal.getActionsAndBehaviors(compId)
    const isDisplayedComponent = coreUtilsLib.displayedOnlyStructureUtil.isDisplayedComponent(compId)

    const compModel = {
        parent: runtimeDal.getParentId(compId),
        type: compType,
        state: compState,
        layout: runtimeDal.getCompLayout(compId),
        design: runtimeDal.getCompDesign(compId, resolveCompDataFromFull),
        isDisplayed: runtimeDal.isDisplayed(compId),
        id: runtimeDal.getCompName(compId),
        data: runtimeDal.getCompData(compId, resolveCompDataFromFull),
        style: runtimeDal.getCompStyle(compId),
        fullData: runtimeDal.getCompData(compId, true),
        props: runtimeDal.getCompProps(compId, resolveCompDataFromFull),
        children: runtimeDal.getChildrenIds(compId),
        events: _.map(compActionsAndBehaviors, actionAndBehavior => _.assign({eventType: _.get(actionAndBehavior, 'action.name')}, _.get(actionAndBehavior, 'behavior.params'))),
        displayedRoot: isDisplayedComponent && repeaterComp ? _.get(repeaterComp, 'id') : null,
        displayedOnlyComponents: isDisplayedComponent ? [] : _.map(runtimeDal.getDisplayedOnlyCompsForFullComp(compId, contextId), 'id')
    }

    return compModel
}

function getCompState(runtimeDal, compId, compType, getCompReactClass, resolveCompDataFromFull) {
    const baseModel = {
        data: runtimeDal.getCompData(compId, resolveCompDataFromFull),
        fullData: runtimeDal.getCompData(compId, true),
        props: runtimeDal.getCompProps(compId, resolveCompDataFromFull)
    }

    const compState = runtimeDal.getCompState(compId)
    if (_.isUndefined(compState) || _.isEmpty(compState)) {
        return getInitialState(baseModel, compType, getCompReactClass)
    }
    return compState
}

function getAllCompsConnections(compIds, runtimeDal, contextId) {
    const connections = _.reduce(compIds, (result, compId) => {
        const compConnections = runtimeDal.getCompConnections(compId)
        const compConnectionsWithIds = _.map(compConnections, connection => {
            let newConnection
            if (connection.type === 'WixCodeConnectionItem') {
                newConnection = _.assign({}, connection, {controllerId: contextId, config: null})
            } else {
                newConnection = _.defaults({}, connection, {config: null})
            }
            return {connection: newConnection, compId}
        })
        return result.concat(compConnectionsWithIds)
    }, [])
    return connections
}

function getConnectionsModel({runtimeDal, compIds, contextId}) {
    const connections = getAllCompsConnections(compIds, runtimeDal, contextId)
    return _(connections)
        .groupBy('connection.controllerId')
        .mapValues(connectionsByController => _(connectionsByController)
            .groupBy('connection.role')
            .mapValues(connectionsByRole => _(connectionsByRole)
                .keyBy('compId')
                .mapValues('connection.config')
                .value())
            .value())
        .value()
}

function getConnectionRoles(compIds, runtimeDal) {
    return _(compIds)
        .flatMap(runtimeDal.getCompConnections)
        .filter('controllerId')
        .groupBy('controllerId')
        .mapValues(controllerConnections => new Set(_.map(controllerConnections, 'role')))
        .value()
}

function getDataMap(components) {
    return _(components)
        .map('data')
        .compact()
        .keyBy('id')
        .value()
}

const getCompIdByCompRole = (connectionsMap, controllerId, componentRole) =>
    _.keys(_.get(connectionsMap, [controllerId, componentRole]))[0]

const getGhostParentId = (parentId, controllerId, fallbackStructure, compRole, realConnections) => {
    if (parentId) {
        return ghostUtils.generateGhostCompId(parentId, controllerId)
    }

    const parentRole = _.findKey(fallbackStructure, potentialParent => _.includes(potentialParent.children, compRole))
    return getCompIdByCompRole(realConnections, controllerId, parentRole) || null
}

const createGhostComponentsAndConnections = (rawGhosts, parentMap, controllerId, displayedOnlyComps, ghostComps, ghostConnections, fallbackStructure, realConnections) => {
    _.forEach(rawGhosts, (comp, compRole) => {
        const parentId = parentMap[compRole]
        const ghostId = ghostUtils.generateGhostCompId(comp.id, controllerId)

        const displayedOnly = displayedOnlyComps.get(compRole)
        const displayedOnlyComponents = _.map(displayedOnly, 'structureId')

        _.forEach(displayedOnly, displayedOnlyComp => {
            const displayedOnlyCompId = displayedOnlyComp.structureId
            delete displayedOnlyComp.structureId
            _.set(ghostComps, displayedOnlyCompId, displayedOnlyComp)
            _.set(ghostConnections, [controllerId, compRole, displayedOnlyCompId], null)
        })

        const ghostComp = _.assign({}, comp, {
            id: compRole,
            data: comp.data || {},
            design: comp.design || {},
            parent: getGhostParentId(parentId, controllerId, fallbackStructure, compRole, realConnections),
            type: comp.componentType,
            children: ghostUtils.getChildrenForGhost(rawGhosts, comp.children || [], controllerId),
            displayedOnlyComponents,
            isDisplayed: !displayedOnlyComps.has(compRole),
            displayedRoot: _.get(comp, 'displayedRoot', null)
        })

        _.set(ghostConnections, [controllerId, compRole, ghostId], null)
        _.set(ghostComps, ghostId, ghostComp)
    })
}


function getGhostCompAndConnectionsModels({ghostStructure = {}, runtimeDal, compIds, realComponents, realConnections}) {
    const ghostComps = {}
    const ghostConnections = {}
    const displayedOnlyComps = new Map()
    const parentMap = {}

    const connectionRoles = getConnectionRoles(compIds, runtimeDal)
    const dataMap = getDataMap(realComponents)

    _.forEach(connectionRoles, (existingConnections, controllerId) => {
        const {applicationId, settings: {type: widgetId} = {}} = dataMap[controllerId] || {}
        const fallbackStructure = _.get(ghostStructure, [applicationId, widgetId], {})

        const ghosts = _.reduce(fallbackStructure, (result, comp, compRole) => {
            if (!existingConnections.has(compRole)) {
                const children = _.get(comp, 'children')
                if (!_.isEmpty(children)) {
                    _.forEach(children, child => _.assign(parentMap, {[child]: _.get(comp, 'id')}))
                }

                if (comp.componentType === REPEATER_TYPE) {
                    ghostUtils.createGhostDisplayedOnlyComps({fallbackStructure, repeater: comp, displayedOnlyComps, controllerId})
                }

                return _.assign(result, {[compRole]: comp})
            }
            return result
        }, {})

        createGhostComponentsAndConnections(ghosts, parentMap, controllerId, displayedOnlyComps, ghostComps, ghostConnections, fallbackStructure, realConnections)
    })
    return {ghostComps, ghostConnections}
}

module.exports = {
    getCompModel,
    getConnectionsModel,
    getGhostCompAndConnectionsModels
}
