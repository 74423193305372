
'use strict'

const _ = require('lodash')

function translateSEOIdToPageId(id, siteData) {
    if (id === '#') {
        return `#${siteData.getMainPageId()}`
    }
    const pageData = siteData.findDataOnMasterPageByPredicate(dataItem => dataItem.pageUriSEO === id.replace('#', ''))
    const pageId = _.get(pageData, 'id', id)
    return !_.startsWith(pageId, '#') ? `#${pageId}` : pageId
}

function getDynamicPageLink(id, siteData) {
    const urlParts = _.compact(id.replace(/^#/, '').split('/'))
    const routerId = _.findKey(siteData.getRouters(), {prefix: urlParts[0]})
    if (routerId) {
        return {
            type: 'DynamicPageLink',
            routerId,
            innerRoute: urlParts.slice(1).join('/')
        }
    }
    return null
}

function getLinksInDataRecursively(data) {
    let result = []

    const link = _.get(data, 'link')
    if (link) {
        result.push(link)
    }
    const linkList = _.get(data, 'linkList')
    if (linkList) {
        result = result.concat(linkList)
    }

    _.forEach(data, propertyVal => {
        if (_.isObject(propertyVal)) {
            result = result.concat(getLinksInDataRecursively(propertyVal))
        }
    })

    return result
}

function getGridLinks(data, props) {
    const linkPaths = _.reduce(props.columns, (paths, columnDef) => {
        if (columnDef.linkPath) {
            paths.push(columnDef.linkPath)
        }
        return paths
    }, [])
    const linkObjects = []
    if (linkPaths.length > 0) {
        _.forEach(data.rows, row => {
            _.forEach(linkPaths, linkPath => {
                const linkObjPath = `${linkPath}_linkObj`
                const linkObj = _.get(row, linkObjPath)
                if (!_.isUndefined(linkObj)) {
                    linkObjects.push(linkObj)
                }
            })
        })
    }
    return linkObjects
}

function resolve(data, siteAPI, {compProps}) {
    const siteData = siteAPI.getSiteData()
    let linksData
    if (data.type === 'Grid') {
        linksData = getGridLinks(data, compProps)
    } else {
        linksData = getLinksInDataRecursively(data)
    }
    _.forEach(linksData, link => {
        const originalLinkType = link.type
        const originalPageId = link.pageId
        if (originalLinkType === 'PageLink' || originalLinkType === 'AnchorLink') {
            if (_.isString(originalPageId)) {
                const dynamicLink = getDynamicPageLink(link.pageId, siteData)
                if (dynamicLink) {
                    _.assign(link, dynamicLink)
                    delete link.pageId
                } else { // TODO: 11/07/2017 Shimi_Liderman this logic has moved to linkUtils in santa-core. It's here for backwards compatibility and should be removed later.
                    link.pageId = translateSEOIdToPageId(originalPageId, siteData)
                }
            }
        }
    })

    return data
}

module.exports = {
    resolve
}
