'use strict'

const _ = require('lodash')
const coreUtilsLib = require('santa-core-utils')

// Widget Lifecycle

function loadAppsInRootsIfNeeded(contextIdsToLoad, contextsToActivate, getApplicationsForContexts, getContextInitData, loadedAppsRoots, widgetHandler) {
    if (!_.isEmpty(contextsToActivate)) {
        loadedAppsRoots = loadApps(getApplicationsForContexts, loadedAppsRoots, contextIdsToLoad, widgetHandler)
        if (!_.isEmpty(loadedAppsRoots)) {
            initApps(contextsToActivate, widgetHandler, getContextInitData)
        }
    }
    return loadedAppsRoots
}

function markAppsAsStarted(loadedAppsRoots, appsRootsToStart) {
    return _.map(loadedAppsRoots, rootObj => _.includes(appsRootsToStart, rootObj.rootId) ? _.assign(rootObj, {started: true}) : rootObj)
}

function updateAppsLifecycle(
    {filterValidContextsForLoad = _.identity, filterContextsToSync = _.identity, filterContextsToActivate = _.identity, getContextInitData, getApplicationsForContexts},
    loadedAppsContexts,
    contextIds,
    widgetHandler
) {
    contextIds = filterContextsToSync(contextIds)

    const applicationsToLoad = getApplicationsForContexts(contextIds)
    if (_.isEmpty(applicationsToLoad)) {
        return loadedAppsContexts
    }
    let contextsToActivate = _.difference(contextIds, _.map(loadedAppsContexts, 'rootId'))
    contextsToActivate = filterContextsToActivate(contextsToActivate)
    const contextIdsToLoad = filterValidContextsForLoad(contextIds)

    loadedAppsContexts = loadAppsInRootsIfNeeded(contextIdsToLoad, contextsToActivate, getApplicationsForContexts, getContextInitData, loadedAppsContexts, widgetHandler)
    const appsContextsToStart = _(loadedAppsContexts)
        .reject({started: true})
        .map('rootId')
        .value()

    if (_.isEmpty(appsContextsToStart)) {
        return loadedAppsContexts
    }

    widgetHandler.startWidgets(appsContextsToStart)
    loadedAppsContexts = markAppsAsStarted(loadedAppsContexts, appsContextsToStart)
    return loadedAppsContexts
}

function getAppsRootIdsToStop(loadedAppsRoots, rootIds) {
    const appsRootsToStop = _.reject(loadedAppsRoots, rootObj => _.includes(rootIds, rootObj.rootId))
    return _.map(appsRootsToStop, 'rootId')
}

function getRootIdsWithLoadedApps(loadedAppsRoots, rootIds) {
    const appsRootsToStop = _.filter(loadedAppsRoots, rootObj => _.includes(rootIds, rootObj.rootId))
    return _.map(appsRootsToStop, 'rootId')
}

function stopLoadedAppsInRoots(loadedAppsRoots, appsRootIdsToStop, widgetHandler) {
    if (_.isEmpty(appsRootIdsToStop) || _.isEmpty(loadedAppsRoots)) {
        return loadedAppsRoots
    }
    widgetHandler.stopWidgets(appsRootIdsToStop)

    return _.reject(loadedAppsRoots, rootObj => _.includes(appsRootIdsToStop, rootObj.rootId))
}

function syncAppsState(
    shouldActivateApps,
    {getContextIdsWhichShouldBeRendered, filterValidContextsForLoad, filterContextsToSync, filterContextsToActivate, getContextInitData, getApplicationsForContexts},
    loadedAppsRoots,
    widgetHandler
) {
    const renderedRootIds = getContextIdsWhichShouldBeRendered()
    if (shouldActivateApps) {
        const rootIdsToStop = getAppsRootIdsToStop(loadedAppsRoots, renderedRootIds)
        loadedAppsRoots = stopLoadedAppsInRoots(loadedAppsRoots, rootIdsToStop, widgetHandler)
        return updateAppsLifecycle(
            {filterValidContextsForLoad, filterContextsToSync, filterContextsToActivate, getContextInitData, getApplicationsForContexts},
            loadedAppsRoots,
            renderedRootIds,
            widgetHandler
        )
    }
    return stopLoadedAppsInRoots(loadedAppsRoots, renderedRootIds, widgetHandler)
}
function triggerAppStudioWidgetOnPropsChanged(pageId, oldProps, newProps, widgetHandler) {
    widgetHandler.triggerAppStudioWidgetOnPropsChanged(pageId, oldProps, newProps)
}

function loadApps(getApplicationsForContexts, loadedAppsRoots, rootIdsToLoad, widgetHandler, pagesInfo) {
    const areAppsInRootLoaded = _(rootIdsToLoad)
        .difference(_.map(loadedAppsRoots, 'rootId'))
        .thru(_.isEmpty)
        .value()
    if (areAppsInRootLoaded) {
        return loadedAppsRoots
    }
    const applicationsToLoad = getApplicationsForContexts(rootIdsToLoad)
    if (_.isEmpty(applicationsToLoad)) {
        return loadedAppsRoots
    }
    widgetHandler.loadWidgets(applicationsToLoad, rootIdsToLoad, pagesInfo)
    const newLoadedRoots = _.map(rootIdsToLoad, rootId => ({rootId}))
    return loadedAppsRoots.concat(newLoadedRoots)
}

function initApps(rootIds, widgetHandler, getContextInitData) {
    const rootIdsToInit = _.without(rootIds, coreUtilsLib.siteConstants.MASTER_PAGE_ID)
    const contextsToInit = _(rootIdsToInit)
        .transform((acc, rootId) => {
            acc[rootId] = getContextInitData(rootId)
        }, {})
        .omitBy(_.isEmpty)
        .value()

    if (_.isEmpty(contextsToInit)) {
        return
    }
    widgetHandler.initWidgets(contextsToInit)
}

function stopApps(loadedAppsRoots, rootIds, widgetHandler, getAllRenderedContextIds) {
    const rootIdsWithLoadedApps = getRootIdsWithLoadedApps(loadedAppsRoots, rootIds)
    const noAppsToStop = _(getAllRenderedContextIds())
        .intersection(rootIdsWithLoadedApps)
        .thru(_.isEmpty)
        .value()
    if (noAppsToStop) {
        return loadedAppsRoots
    }
    return stopLoadedAppsInRoots(loadedAppsRoots, rootIdsWithLoadedApps, widgetHandler)
}

module.exports = {
    syncAppsState,
    loadApps,
    initApps,
    stopApps,
    triggerAppStudioWidgetOnPropsChanged
}
