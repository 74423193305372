
'use strict'

const _ = require('lodash')

const TRANSPARENT_BASE64_PIXEL = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='

function defaultToEmptyImage(data) {
    if (!data.uri) {
        return _.assign({}, data, {uri: TRANSPARENT_BASE64_PIXEL})
    }
    return data
}

function resolve(data) {
    if (data.type === 'Image') {
        return defaultToEmptyImage(data)
    }
    if (data.type === 'ImageList') {
        return _.assign({}, data, {items: data.items && data.items.map(defaultToEmptyImage)})
    }
    return data
}

module.exports = {
    resolve
}
