'use strict'

const _ = require('lodash')

function getUserScript(widget, scriptUrl) {
    const scriptName = `${widget.id}.js`
    return {
        url: scriptUrl,
        displayName: widget.id,
        scriptName
    }
}

function getWixCodeWidgetsExtraData(messageData, widget, scriptUrl) {
    if ((messageData.type === 'load_widgets' || messageData.type === 'load_user_code') && widget.type !== 'Application') {
        return {
            appConfig: {
                userScript: getUserScript(widget, scriptUrl)
            }
        }
    }
    return {}
}

function getExtendedMessage(messageData, scriptUrl) {
    if (messageData.widgets) {
        messageData.widgets = _.map(messageData.widgets, widgetData => _.assign({}, widgetData, getWixCodeWidgetsExtraData(messageData, widgetData, scriptUrl)))
    }

    return _.defaults({}, messageData)
}


module.exports = {
    getExtendedMessage
}
