'use strict'

const _ = require('lodash')

class ActionQueue {
    constructor() {
        this.queue = []
    }
    flush() {
        _.forEach(this.queue, action => action())
        this.queue = []
    }
    addItem(item) {
        this.queue.push(item)
    }
}

module.exports = ActionQueue